.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

body {
  color: #989fb2;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 42px;
  font-weight: 600;
  line-height: 1.2;
}

h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
}

h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}

p {
  margin-bottom: 0;
  font-size: 15px;
}

ul, ol {
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 40px;
}

label {
  font-weight: 700;
  display: block;
}

.holder {
  width: 100%;
  height: 100vh;
  max-width: none;
  grid-column-gap: 0px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: minmax(260px, .25fr) 1fr minmax(245px, .25fr);
  grid-auto-columns: 1fr;
  justify-items: stretch;
  text-decoration: none;
  display: grid;
  position: relative;
  overflow: hidden;
}

.menu-left {
  height: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: #f8f8f8;
  border-right: 1px solid #edebec;
  flex-direction: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.menu-right {
  height: 100%;
  background-color: #fff;
  border-left: 1px solid #edebec;
}

.menu-projects {
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  padding-top: 34px;
  padding-left: 24px;
  padding-right: 24px;
  text-decoration: none;
  overflow: hidden;
}

.menu-projects.template {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.menu-l__wrap {
  width: 100%;
}

.logo-wrap {
  margin-bottom: 70px;
  padding-top: 34px;
  padding-left: 34px;
  padding-right: 40px;
}

.menu-l__h {
  color: #080045;
  margin-top: 0;
  margin-bottom: 16px;
  padding-left: 34px;
  font-size: 13px;
  line-height: 120%;
}

.menu-link {
  grid-column-gap: 12px;
  filter: grayscale();
  color: #4971ff;
  border-left: 5px solid rgba(0, 0, 0, 0);
  flex-direction: row;
  align-items: center;
  padding: 10px 12px 10px 29px;
  font-size: 14px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.menu-link:hover {
  border-left: 5px solid #818181;
}

.menu-link.w--current {
  filter: grayscale(0%);
  border-left: 5px solid #4971ff;
  padding-left: 29px;
}

.logout {
  grid-column-gap: 12px;
  color: #989fb2;
  align-items: center;
  margin-bottom: 30px;
  margin-left: 20px;
  padding: 10px 20px 10px 14px;
  font-size: 14px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  cursor: pointer;
}

.logout:hover {
  color: #4971ff;
}

.menu-r__wrap {
  padding-top: 34px;
  padding-left: 16px;
  padding-right: 16px;
}

.menu-r__header {
  grid-column-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 27px;
  display: flex;
}

.text-dark {
  color: #080045;
  text-decoration: none;
  font-size: 13px;
}

.text-dark.mb-24 {
  display: none;
}

.mb-24 {
  margin-bottom: 24px;
}

.stats-grid {
  grid-row-gap: 22px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

/* .simple-shadow-small {
  box-shadow: -6px 6px 20px rgba(0, 0, 0, .12);
} */

.stats-grid__flex {
  grid-column-gap: 5px;
  align-items: flex-start;
  margin-top: 5px;
  display: flex;
}

.small-circle {
  width: 6px;
  height: 6px;
  background-color: #06d7a1;
  border-radius: 50%;
  margin-top: 4px;
}

.small-circle.orange {
  background-color: #ffc62a;
}

.small-circle.blue {
  background-color: #4971ff;
}

.small-circle.pink {
  background-color: #ff5ff9;
}

.small-circle.gray {
  background-color: #989fb2;
}

.stats-number {
  color: #080045;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.2;
}

.border {
  width: 100%;
  height: 1px;
  background-color: #edebec;
  margin-top: 24px;
  margin-bottom: 24px;
}

.border.no-bot-m {
  margin-bottom: 0;
}

.border.mt-16 {
  margin-top: 16px;
}

.border.m-12 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.members-wrap {
  display: none;
}

.member-img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.member-img.small {
  width: 28px;
  height: 28px;
}

.member-info {
  grid-column-gap: 10px;
  align-items: center;
  display: flex;
}

.members-list {
  max-height: 380px;
  grid-row-gap: 8px;
  flex-direction: column;
  align-items: stretch;
  display: flex;
  overflow: auto;
}

.projects__heading.mb-16 {
  margin-bottom: 16px;
}

.projects-head__flex {
  grid-column-gap: 10px;
  align-items: center;
  margin-bottom: 8px;
  display: flex;
}

.h-24 {
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
}

.filters {
  justify-content: space-between;
  align-items: center;
  display: none;
}

.filters-wrap {
  grid-column-gap: 10px;
  align-items: center;
  display: flex;
}

.filter-dd {
  grid-column-gap: 24px;
  background-color: #f8f8f8;
  border-radius: 8px;
  align-items: center;
  padding: 10px 8px 10px 14px;
  transition: all .2s;
  display: flex;
  position: relative;
}

.projects-wrapper {
  grid-column-gap: 24px;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 6px;
  display: flex;
  overflow: auto;
}

.projects-wrapper.grid {
  background-color: #f8f8f8;
  border-radius: 20px;
  flex-direction: column;
  display: flex;
  overflow: auto;
}

.projects-column {
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 20px;
  padding: 20px 5px 20px 16px;
  overflow: hidden;
}

.projects-column.grid {
  max-width: none;
  flex: 1;
  padding-top: 14px;
  padding-bottom: 14px;
  overflow: visible;
}

.projects-column.grid.first {
  padding-top: 20px;
}

.projects-column.grid.last {
  padding-bottom: 20px;
}

.projects-count__wrap {
  grid-column-gap: 8px;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.projects-count__wrap.other {
  grid-column-gap: 8px;
  align-items: center;
  display: flex;
}

.projects-count__wrap.withSettings {
  grid-column-gap: 8px;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}

.h-16 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
}

.h-16.text-dark.mb-5 {
  border: 1px #000;
  text-decoration: none;
}

.count-box {
  width: 27px;
  height: 27px;
  color: #4971ff;
  background-color: rgba(73, 113, 255, .05);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  display: flex;
}

.projects-collection {
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
}

.projects-list {
  grid-row-gap: 16px;
  flex-direction: column;
  display: flex;
}

.projects-list.grid {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-flow: wrap;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: flex;
}

.project {
  width: 20vw;
  max-width: 370px;
  min-width: 250px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  color: #989fb2;
  background-color: #fff;
  border: 1px solid #edebec;
  border-radius: 20px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  padding: 20px;
  transition: border-color .2s;
  display: inline-block;
  text-decoration: none;
}

.project:hover {
  border-color: #4971ff;
}

.project.grid {
  width: 100%;
  max-width: 310px;
}

.project-heading {
  grid-column-gap: 12px;
  color: #989fb2;
  align-items: center;
  margin-bottom: 14px;
  text-decoration: none;
  display: flex;
}

.project-logo__wrap {
  width: 40px;
  height: 40px;
  border: 1px solid #edebec;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.mb-5 {
  margin-bottom: 5px;
}

.label {
  color: #ff6da2;
  background-color: rgba(255, 109, 162, .05);
  border-radius: 8px;
  padding: 6px 10px;
  font-size: 12px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

.assignees {
  grid-column-gap: 16px;
  align-items: center;
  display: none;
}

.dates-wrap {
  grid-column-gap: 24px;
  justify-content: flex-start;
  display: flex;
}

.p-11 {
  font-size: 11px;
}

.p-11.mb-10 {
  text-decoration: none;
}

.mb-10 {
  margin-bottom: 10px;
}

.date-wrapper {
  grid-column-gap: 5px;
  align-items: center;
  display: flex;
}

.label-bg {
  opacity: .05;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.paragraph {
  z-index: 1;
  position: relative;
}

.number-wrap {
  align-items: center;
  display: flex;
}

.upcoming {
  display: block;
}

.scroll-wrap {
  width: 100%;
  height: 100vh;
  max-height: 100%;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 40px;
  padding-right: 5px;
  display: flex;
  overflow: auto;
}

.empty-state {
  color: #4971ff;
  background-color: rgba(0, 0, 0, 0);
  padding-left: 0;
  font-size: 15px;
}

.labels-wrap {
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.none, .cateogires-wrap, .fs-assignees {
  display: none;
}

.assignee {
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  color: #989fb2;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.assignee.none {
  display: none;
}

.assignee-image {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.fs-labels {
  display: none;
}

.assignees-wrap {
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.dd-toggle {
  justify-content: space-between;
  padding: 0;
  display: flex;
}

.dd-toggle.w--open {
  width: 100%;
}

.dd-toggle.text-light {
  color: #989fb2;
}

.dd-nav {
  width: 100%;
  padding: 13px 12px 13px 34px;
  cursor: pointer;
}

.dd-toggle-flex {
  grid-column-gap: 12px;
  align-items: center;
  display: flex;
}

.dd-text {
  color: #737373;
  font-size: 15px;
}

.menu-link__dd {
  grid-column-gap: 12px;
  filter: grayscale();
  color: #4971ff;
  border-left: 5px solid rgba(0, 0, 0, 0);
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  font-size: 14px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.menu-link__dd:hover {
  border-left: 5px solid #818181;
}

.menu-link__dd.w--current {
  filter: grayscale(0%);
  border-left: 5px solid #4971ff;
  padding-left: 40px;
}

.finsweet {
  display: none;
}

.template-header {
  background-color: #f8f8f8;
  border-bottom: 1px solid #edebec;
  padding: 34px 24px 24px;
}

.map-wrap {
  grid-column-gap: 3px;
  align-items: center;
  margin-bottom: 34px;
  display: flex;
}

.p {
  color: #989fb2;
  text-decoration: none;
}

.text-blue {
  color: #4971ff;
  font-size: 13px;
}

.info-wrap {
  grid-column-gap: 20px;
  align-items: center;
  display: flex;
}

.logo-big {
  width: 80px;
  height: 80px;
  border: 1px solid #edebec;
  border-radius: 8px;
}

.mb-14 {
  margin-bottom: 14px;
}

.header-wrapper {
  grid-row-gap: 30px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.border-vertical {
  width: 1px;
  background-color: #edebec;
  margin-left: 40px;
  margin-right: 40px;
}

.services-list {
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  flex-wrap: wrap;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-content: flex-start;
  align-items: center;
  display: flex;
}

.services-wrap {
  max-width: 245px;
}

.dates-wrapper {
  border-right: 1px solid #edebec;
  margin-right: 40px;
  padding-right: 40px;
}

.mb-12 {
  margin-bottom: 12px;
}

.links {
  grid-column-gap: 22px;
  grid-row-gap: 11px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: center;
  display: grid;
}

.link-icon {
  width: 28px;
  height: 28px;
  border: 1px solid #edebec;
  border-radius: 4px;
}

.link-flex {
  grid-column-gap: 8px;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.link-flex:hover {
  color: #4971ff;
}

.link-flex.text-dark {
  transition: all .2s;
}

.members-wrapper {
  grid-column-gap: 24px;
  grid-row-gap: 25px;
  border-top: 1px solid #edebec;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 24px;
  padding-top: 20px;
  display: flex;
}

.member-role {
  grid-column-gap: 8px;
  align-items: center;
  display: flex;
}

.template-body {
  padding-top: 20px;
  padding-left: 24px;
  padding-right: 10px;
}

.tab-link {
  color: #080045;
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 10px 14px;
  transition: all .2s;
}

.tab-link:hover {
  box-shadow: 0 0 30px rgba(0, 0, 0, .15);
}

.tab-link.w--current {
  color: #fff;
  background-color: #4971ff;
}

.tabs-menu {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 35px;
  display: flex;
}

.tabs-content {
  overflow: auto;
}

.rte-project {
  color: #080045;
}

.rte-project h2 {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
}

.rte-project p {
  line-height: 24px;
}

.rte-project ul {
  padding-left: 27px;
  font-size: 15px;
  font-weight: 400;
}

.rte-project li {
  margin-bottom: 5px;
}

.rte-project ol {
  padding-left: 27px;
  font-size: 15px;
  font-weight: 400;
}

.tab-panel {
  overflow: auto;
}

.label-p {
  z-index: 1;
  font-size: 12px;
  position: relative;
}

.p-13 {
  font-size: 13px;
}

.p-13.text-light {
  color: #989fb2;
}

.p-12 {
  font-size: 12px;
}

.no-p {
  grid-column-gap: 24px;
  padding: 0;
  display: flex;
}

.dd-text-flex {
  grid-column-gap: 5px;
  align-items: center;
  display: flex;
}

.dd-list-filter {
  position: absolute;
  top: 35px;
}

.dd-list-filter.w--open {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  top: 40px;
  left: 0;
  box-shadow: 0 0 40px rgba(0, 0, 0, .15);
}

.form-block {
  margin-bottom: 0;
}

.collection-list {
  grid-row-gap: 16px;
  flex-direction: column;
  display: flex;
}

.collection-list.filter-complex {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: wrap;
  margin-left: auto;
  margin-right: auto;
}

.documentation-wrap {
  width: 100%;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
}

.documentation-menu {
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  padding-top: 34px;
  padding-left: 15px;
  padding-right: 15px;
}

.border-bot {
  border-bottom: 1px solid #edebec;
  padding-bottom: 16px;
}

.documentation-content {
  padding-top: 16px;
  padding-bottom: 15px;
  padding-right: 5px;
  overflow: auto;
}

.invoices-wrap {
  width: 100%;
  max-width: 1370px;
  margin-left: auto;
  margin-right: auto;
}

.invoices-wrapper {
  border-top: 1px #edebec;
  margin-top: 60px;
  margin-right: 5px;
  padding-top: 15px;
  padding-bottom: 90px;
  padding-right: 5px;
  overflow: auto;
}

.invoices-list {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
}

.invoice {
  color: #989fb2;
  background-color: #f8f8f8;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 17px 21px 17px 17px;
  text-decoration: none;
  display: flex;
  position: relative;
  transition: transform 0.2s cubic-bezier(.25, .46, .45, .94);
}
.settings-button{
  background-color: #ffffff00;
  border-radius: 5px;
}
.dragItem{
  color: #989fb2;
  background-color: #f8f8f8;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 17px 21px 17px 17px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.options-panel{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.invoice:hover {
  transform: translateY(-5px);
}

.invoice-r {
  grid-column-gap: 10px;
  align-items: center;
  display: flex;
  color: originalColor;
  transition: color 0.2s cubic-bezier(.25, .46, .45, .94);
}

.invoice:hover .invoice-r {
  /* Change text color to #4971ff */
  color: #4971ff;
}

.invoice-l {
  grid-column-gap: 12px;
  align-items: center;
  display: flex;
}


.invoice-icon {
  width: 41px;
  height: 41px;
  object-fit: cover;
  border: 1px solid #edebec;
  border-radius: 8px;
  display: none;
}

.p-16 {
  font-size: 16px;
}

.inv-date-wrap {
  align-items: center;
  display: flex;
}

.dot {
  width: 4px;
  height: 4px;
  background-color: #989fb2;
  border-radius: 50%;
  margin-left: 8px;
  margin-right: 8px;
}

.p-14 {
  font-size: 14px;
}

.settings-wrapper {
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

.settings-wrap {
  margin-right: -10px;
  padding-bottom: 20px;
  padding-right: 10px;
  overflow: auto;
}

.link-without-decoration {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

.link-text {
  color: #989fb2;
}

.email-form, .password-form {
  margin-top: 40px;
}

.form-label {
  font-weight: 500;
  text-transform: capitalize;
}

.form-field {
  height: 48px;
  border-radius: 4px;
  margin-bottom: 0;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
}

.form-field::-ms-input-placeholder {
  font-size: 15px;
  font-weight: 400;
}

.form-field::placeholder {
  font-size: 15px;
  font-weight: 400;
}

.form-field-wrap {
  width: 100%;
}

.form-flex {
  grid-column-gap: 24px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.form-flex.vertical {
  flex-direction: column;
}

.h-20 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

.no-m {
  margin-bottom: 0;
}

.file-input {
  background-color: #9d9d9d;
  border-radius: 4px;
  align-self: flex-end;
  margin-right: 24px;
  padding: 16px 34px;
  font-size: 12px;
  font-weight: 600;
  transition: all .2s;
  color: #fff;
  cursor: pointer;
}

.file-input:hover {
  background-color: rgba(207, 207, 207, 0.8);
}

.flex-start {
  align-self: flex-start;
}
/* file form upload */


.div-block-copy {
  z-index: 9;
  min-height: 50px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -webkit-text-stroke-width: 0px;
  object-fit: fill;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-flow: row;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  padding: 10px;
  display: flex;
  position: relative;
}
.div-block-copy-array {
  z-index: 9;
  min-height: 50px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -webkit-text-stroke-width: 0px;
  object-fit: fill;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5px;
  padding: 10px;
  display: flex;
  position: relative;
}
.array-items-wrapper {
  width: 100%;
  z-index: 9;
  min-height: 50px;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -webkit-text-stroke-width: 0px;
  object-fit: fill;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-flow: row;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  padding: 10px;
  display: flex;
  position: relative;
}
.preview-image{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  filter: contrast(0.5);
}
.text-block-2 {
  color: #000;
}

.image-2 {
  width: 25px;
  height: 25px;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
}

.button-wrapper {
  flex-flow: row;
  flex: 0 auto;
  justify-content: center;
  align-self: auto;
  align-items: center;
  margin-left: auto;
  display: flex;
  position: relative;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.icon-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: invert(0.9);
}

.image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.image-container {
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}

.large-image {
  max-width: 80vw;
  max-height: 80vh;
}

/* Additional styles for body when viewer is open */
body {
  overflow: auto;
}

body.modal-open {
  overflow: hidden;
}

.view-btn {
  min-height: 40px;
  min-width: 40px;
  color: #fff;
  -webkit-text-stroke-color: #ccc;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 4px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: 5px;
  padding-top: 9px;
  position: relative;
  width: 44px; /* Set the desired width of the parent div */
  height: 44px; /* Set the desired height of the parent div */
  overflow: hidden;
}

.view-btn path{
  stroke: #000;
  transition: all 0.1s cubic-bezier(.25, .46, .45, .94);
}

.view-btn:hover path{
  stroke: #ffffff;
}

.view-btn circle{
  stroke: #000;
  transition: all 0.1s cubic-bezier(.25, .46, .45, .94);
}

.view-btn:hover circle{
  stroke: #ffffff;
}

.delete-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  /* filter: contrast(50%); */
  margin-left: 5px;
  margin-right: 5px;
  width: 44px; /* Set the desired width of the parent div */
  height: 44px; /* Set the desired height of the parent div */
  transition: background-color 0.2s cubic-bezier(.25, .46, .45, .94);
}

.delete-btn path {
  stroke: #000;
  transition: all 0.1s cubic-bezier(.25, .46, .45, .94);
}

.delete-btn:hover path {
  stroke: #ffffff !important;
}

.delete-btn:hover {
  background-color: #d62a2a;
}

/* --------------------------------------- */
.file-input:focus {
  outline: none;
}

.file-input input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.submit-button {
  background-color: #4971ff;
  border-radius: 4px;
  align-self: flex-end;
  margin-top: 24px;
  padding: 16px 34px;
  font-size: 14px;
  font-weight: 600;
  transition: all .2s;
}

.submit-button:hover {
  background-color: rgba(73, 113, 255, .8);
}

.submit-button.in-form {
  width: 100%;
  background-color: #4971ff;
  margin-top: 0;
}

.submit-button.in-form:hover {
  background-color: rgba(73, 113, 255, .8);
}

.form-wrap {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.link {
  color: #434de7;
  font-weight: 600;
  text-decoration: none;
}

.link:hover {
  color: #000;
}

.tour-step-2 {
  display: none;
}

.tooltip-author-wrap {
  align-items: center;
  display: flex;
}

.small-h1 {
  color: #000;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.2em;
}

.flex-row-outer {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.flex-row-outer.margin-bottom {
  margin: 0 0 12px;
}

.form-container {
  width: 100%;
  text-align: left;
  flex-flow: column wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-top: 10px;
  display: flex;
  position: relative;
}

.tooltip-wrapper {
  width: 300px;
  color: #333;
  text-align: left;
  background-color: #fafbfe;
  border: 1px solid #d8e3f0;
  border-radius: 10px;
  font-size: 11.5px;
  font-weight: 400;
  line-height: 1.5em;
  display: block;
  position: absolute;
  box-shadow: 1px 1px 8px 1px rgba(45, 62, 80, .12);
}

.show-password {
  color: #989fb2;
  background-color: #fff;
  padding: 0 7px 0 6px;
  font-size: 13.5px;
  display: flex;
  bottom: -1px;
}

.show-password.w--current {
  display: none;
}

.tooltip-title {
  margin-bottom: 6px;
  font-size: 110%;
  font-weight: 800;
}

.hide {
  display: none;
}

.tooltip-container {
  z-index: 3;
  background-color: #fafbfe;
  border-radius: 9px 9px 0 0;
  padding: 20px;
  font-size: 12.5px;
  position: relative;
}

.tooltip-close {
  z-index: 5;
  width: 24px;
  height: 24px;
  color: #3f3e46;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.tooltip-close:hover {
  background-color: rgba(0, 42, 255, .1);
}

.tooltip-diamond {
  z-index: 2;
  width: 16px;
  height: 16px;
  background-color: #fafbfe;
  border: 1px solid #d8e3f0;
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: -7px;
  bottom: auto;
  left: 0%;
  right: 0%;
  transform: rotate(45deg);
  box-shadow: 0 -3px 8px rgba(45, 62, 80, .06);
}

.tooltip-diamond.left {
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 10px;
  left: -7px;
  right: auto;
}

.show-password-wrap {
  z-index: 4;
  position: absolute;
  top: 12px;
  bottom: auto;
  left: auto;
  right: 3%;
}

.tooltip-svg {
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.eye-svg {
  width: 16px;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  display: flex;
}

.tooltip-author {
  width: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.form-wrapper {
  z-index: 50;
  width: 100%;
  max-width: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  position: relative;
  overflow: visible;
}

.input-wrapper {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;
  display: flex;
  position: relative;
}

.content-wrap-center {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.content-wrap-center._100-p-width {
  width: 100%;
}

.full-section {
  width: 100%;
  height: auto;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 5%;
  display: none;
}

.tooltip-anchor {
  z-index: 100;
  width: 100%;
  height: 10px;
  justify-content: center;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  padding-top: 12px;
  display: flex;
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 0%;
  right: 0%;
}

.tooltip-anchor.right {
  width: auto;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-right: -12px;
  padding-left: 12px;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.tooltip-navigation {
  z-index: 3;
  min-height: 50px;
  color: #64636e;
  background-color: #fafbfe;
  border-top: 1px solid #d8e3f0;
  border-radius: 0 0 9px 9px;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  display: flex;
  position: relative;
  bottom: 0;
}

.tooltip-navigation.top {
  border-top-style: none;
  border-bottom: 1px solid #d8e3f0;
  border-radius: 9px 9px 0 0;
}

.relative-block {
  position: relative;
}

.agency-logo {
  width: 120px;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
}

.hidden-code-block {
  height: 0;
  overflow: hidden;
}

.spacer-s {
  width: 100%;
  height: 15px;
}

.relative-field-wrap {
  text-align: left;
  position: relative;
}

.description {
  opacity: .6;
  color: #000;
  margin-top: 3px;
  padding-left: 0;
  font-size: 12px;
  font-weight: 300;
}

.spacer-xs {
  width: 100%;
  height: 10px;
}

.input-description {
  opacity: .8;
  margin-top: 5px;
  margin-bottom: 6px;
  padding-left: 12px;
  font-size: 12px;
  font-weight: 300;
  transition: height .2s;
  overflow: hidden;
}

.input-description.success, .input-description.error {
  color: #000;
  padding-left: 0;
  font-weight: 400;
}

.confirm-input-wrapper {
  width: 100%;
  text-align: left;
  flex-direction: column;
}

.confirm-input-wrapper.hidden {
  text-align: left;
  transition: height .2s;
  overflow: hidden;
}

.menu-tabs {
  z-index: 10;
  background-color: #f8f8f8;
  border: 1px solid #edebec;
  border-radius: 8px;
  align-items: center;
  display: flex;
  position: absolute;
  right: 0;
  overflow: hidden;
}

.menu-add-item {
  z-index: 10;
  background-color: #f8f8f8;
  border: 1px solid #edebec;
  border-radius: 8px;
  align-items: center;
  display: flex;
  position: absolute;
  left: 0;
  overflow: hidden;
}

.add-item-button {
  background-color: #4971ff;
  border-radius: 4px;
  align-self: flex-end;
  padding: 16px 34px;
  font-size: 14px;
  font-weight: 600;
  transition: all .2s;
}

.add-item-button:hover {
  background-color: rgba(73, 113, 255, .8);
}

.tabs-home {
  position: relative;
}

.tab-switch {
  width: 36px;
  height: 36px;
  color: #989fb2;
  background-color: #f8f8f8;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.tab-switch.w--current {
  color: #fff;
  background-color: #4971ff;
  border-radius: 8px;
}

.checkbox-button {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-color: #e9e7e8;
  border-radius: 4px;
  margin-top: 0;
  margin-left: 0;
}

.checkbox-button.w--redirected-checked {
  background-color: #4971ff;
  background-image: url('../images/checkmark.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
  border-width: 1px;
  border-color: #4971ff;
}

.checkbox-field {
  grid-column-gap: 10px;
  color: #080045;
  white-space: pre;
  cursor: pointer;
  align-items: center;
  margin-bottom: 16px;
  margin-left: -10px;
  padding-left: 0;
  display: flex;
}

.form-filter {
  grid-column-gap: 10px;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
}

.checkbox-button-assigne {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-color: #e9e7e8;
  border-radius: 4px;
  margin-top: 0;
  margin-left: 0;
}

.checkbox-button-assigne.w--redirected-checked {
  background-color: #4971ff;
  background-image: url('../images/checkmark.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
  border-width: 1px;
  border-color: #4971ff;
}

.centered {
  margin-top: 4px;
}

.assignee-flex {
  grid-column-gap: 20px;
  grid-row-gap: 5px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.update-flex {
  grid-column-gap: 5px;
  align-items: center;
  display: flex;
}

.hamburger-wrap {
  display: none;
}

.stats-flex {
  grid-column-gap: 8px;
  display: flex;
}

.doc-dd__list {
  position: relative;
  top: 10px;
}

.doc-dd__list.w--open {
  width: 255px;
  max-height: 320px;
  background-color: rgba(0, 0, 0, 0);
  padding-right: 15px;
  left: -34px;
  overflow: auto;
}

.member-name {
  color: #080045;
}

.nav_logo {
  height: 1.6875rem;
}

.disclaimer {
  margin-top: 25px;
  font-size: 13px;
}

.container {
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.redirect {
  height: 100vh;
  color: #080045;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.csilver {
  color: #989fb2;
  font-weight: 400;
}

.redirect-wrap {
  display: none;
}

.disclaimer-section {
  background-color: #4971ff;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 165px 40px 40px;
  display: none;
  position: relative;
}

.disclaimer-h1 {
  color: #fff;
  margin-bottom: 10px;
  font-size: 30px;
}

.white-text {
  color: #e8e8e9;
}

.text-link {
  color: #fff;
  font-weight: 600;
}

.close-icon {
  width: 24px;
  height: 24px;
  color: #fff;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 40px;
  right: 40px;
  overflow: hidden;
}

.button {
  color: #4971ff;
  background-color: #fff;
  border-radius: 4px;
  align-self: flex-end;
  margin-top: 0;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  transition: all .2s;
}

.button:hover {
  opacity: .9;
}

.button.in-form {
  width: 100%;
  background-color: #4971ff;
  margin-top: 0;
}

.button.in-form:hover {
  background-color: rgba(73, 113, 255, .8);
}

._2-button-wrap {
  grid-template-rows: auto;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.assigne-checkbox, .services-checkbox {
  grid-column-gap: 10px;
  color: #080045;
  white-space: pre;
  cursor: pointer;
  align-items: center;
  margin-bottom: 16px;
  margin-left: -10px;
  padding-left: 0;
  display: flex;
}

.ms-iframe {
  z-index: 2147483647;
  height: 70px;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  position: fixed;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.ms-iframe-embed {
  width: 180px;
  height: 70px;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  position: relative;
  overflow: visible;
}

.ms-iframe-footer-embed {
  width: 100%;
  height: 350px;
  background-color: #d1e7fb;
  background-image: url('../images/noise.png');
  background-position: 0 0;
  background-size: 200px;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 0;
  display: block;
  overflow: hidden;
}

.hidden-embed {
  z-index: -1;
  height: 0;
  max-height: 0;
  opacity: 0;
  position: fixed;
}

.date-copy {
  display: none;
}

.body {
  text-decoration: none;
}

.projects-column-copy {
  width: 100%;
  max-width: 340px;
  background-color: #f8f8f8;
  border-radius: 20px;
  flex: none;
  padding: 20px 5px 20px 16px;
  display: none;
  overflow: hidden;
}

.projects-column-copy.grid {
  max-width: none;
  flex: 1;
  padding-top: 14px;
  padding-bottom: 14px;
  overflow: visible;
}

.projects-column-copy.grid.first {
  padding-top: 20px;
}

.projects-column-copy.grid.last {
  padding-bottom: 20px;
}

.projects-column-copy {
  width: 100%;
  max-width: 340px;
  background-color: #f8f8f8;
  border-radius: 20px;
  flex: none;
  padding: 20px 5px 20px 16px;
  overflow: hidden;
}

.projects-column-copy.grid {
  max-width: none;
  flex: 1;
  padding-top: 14px;
  padding-bottom: 14px;
  overflow: visible;
}

.projects-column-copy.grid.first {
  padding-top: 20px;
}

.projects-column-copy.grid.last {
  padding-bottom: 20px;
}

.projects-column-copy {
  width: 100%;
  max-width: 340px;
  background-color: #f8f8f8;
  border-radius: 20px;
  flex: none;
  padding: 20px 5px 20px 16px;
  overflow: hidden;
}

.projects-column-copy.grid {
  max-width: none;
  flex: 1;
  padding-top: 14px;
  padding-bottom: 14px;
  overflow: visible;
}

.projects-column-copy.grid.first {
  padding-top: 20px;
}

.projects-column-copy.grid.last {
  padding-bottom: 20px;
}

.tabs-content-2 {
  margin-top: 30px;
}

.stats-grid__item-copy {
  display: none;
}

.centered-copy {
  margin-top: 4px;
  display: none;
}

.tab-pane-tab-2 {
  margin-top: -50px;
  margin-left: 20px;
  margin-right: 20px;
}

.image {
  display: none;
}

.spark-centered-form {
  width: 388px;
  max-width: 100%;
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
}

.spark-centered-form.spark-simple-shadow-small {
  color: #000;
}

.spark-section {
  width: 100%;
  padding: 176px 64px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
  position: relative;
}

.spark-section.spark-secondary-background {
  background-color: #fff;
}

.spark-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.spark-button {
  color: #fff;
  background-color: #5532fa;
  border: 1px solid #5532fa;
  border-radius: 8px;
  flex: none;
  padding: 16px;
  font-size: .9375rem;
  font-weight: 500;
  line-height: 1em;
  text-decoration: none;
  transition: border-color .15s, background-color .15s, opacity .15s;
}

.spark-button:hover {
  opacity: 1;
  background-color: #1e116e;
  border-color: #1e116e;
}

.spark-button.spark-full-width {
  width: 100%;
  text-align: center;
  background-color: #5532fa;
}

.spark-button.spark-full-width:hover {
  background-color: #1e116e;
}

.spark-form-heading {
  text-align: center;
  margin-bottom: 32px;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.7em;
}

.spark-form-success {
  text-align: center;
  background-color: #f0ecfd;
  border-radius: 8px;
  padding: 24px;
}

.spark-centered-text-link {
  color: rgba(0, 0, 0, .76);
  text-align: center;
  margin-top: 24px;
  text-decoration: none;
  display: block;
}

.spark-centered-logo {
  width: 132px;
  margin-bottom: 48px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.spark-input {
  height: 48px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .16);
  border-radius: 8px;
  margin-bottom: 32px;
  padding: 8px 16px;
  font-size: 1rem;
}

.spark-input:focus {
  border-color: #5532fa;
}

.spark-input::-ms-input-placeholder {
  color: rgba(0, 0, 0, .56);
}

.spark-input::placeholder {
  color: rgba(0, 0, 0, .56);
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 38px;
  }

  .holder {
    display: flex;
    position: relative;
  }

  .holder.project-template {
    height: auto;
  }

  .menu-left {
    z-index: 9999;
    width: 50%;
    opacity: 0;
    display: none;
    position: absolute;
  }

  .menu-left.open {
    opacity: 1;
    display: flex;
    position: absolute;
  }

  .menu-right {
    z-index: 9999;
    width: 50%;
    opacity: 0;
    display: none;
    position: absolute;
    right: 0;
  }

  .menu-projects {
    display: block;
  }

  .logo-wrap.homepage {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .menu-r__header {
    justify-content: space-between;
  }

  .members-list {
    max-height: 330px;
  }

  .projects__heading {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .border-vertical.second {
    display: none;
  }

  .tab-panel {
    overflow: visible;
  }

  .border-bot.flex {
    grid-column-gap: 15px;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .hamburger-wrap {
    width: 30px;
    grid-row-gap: 3px;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .hamburger-wrap.x {
    height: 30px;
    justify-content: center;
  }

  .hamburger-line {
    width: 100%;
    height: 3px;
    background-color: #080045;
    padding-bottom: 0;
    padding-right: 0;
  }

  .hamburger-line.top {
    transform: translate(0, 3px)rotate(45deg);
  }

  .hamburger-line.bottom {
    transform: translate(0, -3px)rotate(-45deg);
  }

  .stats-flex {
    grid-column-gap: 8px;
    display: flex;
  }

  .map-flex {
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
  }

  .spark-section {
    padding-top: 104px;
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 35px;
  }

  .menu-left {
    width: 70%;
  }

  .menu-projects {
    padding-top: 20px;
  }

  .filter-dd {
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;
  }

  .no-p {
    width: 100%;
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;
  }

  .invoice {
    grid-row-gap: 30px;
    flex-direction: column;
    align-items: flex-start;
  }

  .form-flex.password {
    grid-row-gap: 15px;
    flex-direction: column;
  }

  .form-filter {
    grid-row-gap: 10px;
    flex-flow: column wrap;
    align-items: stretch;
  }

  .nav_logo-link {
    padding-left: 0;
  }

  .nav_logo {
    height: 1.5rem;
  }

  .spark-section {
    padding-top: 88px;
    padding-bottom: 88px;
  }

  .spark-container {
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 30px;
  }

  .menu-left {
    width: 100%;
  }

  .menu-projects {
    padding-left: 15px;
    padding-right: 15px;
  }

  .logo-wrap {
    margin-bottom: 20px;
    padding-top: 20px;
    padding-left: 17px;
    padding-right: 0;
  }

  .menu-link, .menu-link.w--current {
    padding-left: 15px;
  }

  .logout {
    margin-bottom: 15px;
    margin-left: 6px;
    padding-right: 15px;
  }

  .menu-r__wrap {
    padding-top: 18px;
  }

  .menu-r__header {
    margin-bottom: 20px;
  }

  .stats-grid {
    grid-column-gap: 10px;
    grid-row-gap: 15px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .h-24.text-dark.stats {
    font-size: 20px;
    font-weight: 700;
  }

  .dd-toggle.text-light {
    grid-column-gap: 20px;
    justify-content: flex-start;
  }

  .dd-nav {
    padding-left: 20px;
  }

  .header-wrapper {
    flex-direction: column;
  }

  .border-vertical.phone-none {
    display: none;
  }

  .dates-wrapper {
    border-right-style: none;
  }

  .links {
    grid-template-columns: 1fr 1fr;
  }

  .members-wrapper {
    grid-column-gap: 15px;
    grid-row-gap: 20px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .email-form, .password-form {
    margin-top: 30px;
  }

  .hamburger-wrap.x {
    margin-right: 20px;
  }

  .logo-img {
    max-width: 90%;
  }

  .doc-dd__list.w--open {
    width: 200px;
  }

  .member-name {
    font-size: 13px;
    font-weight: 600;
  }

  .doc-p {
    font-size: 13px;
  }

  .mw-mobile-330 {
    width: 100%;
    max-width: 330px;
  }

  .spark-section {
    padding: 64px 20px;
  }
}

#w-node-ddcce60c-7d28-6fd3-2508-07df49ebf169-49ebf160, #w-node-ddcce60c-7d28-6fd3-2508-07df49ebf170-49ebf160, #w-node-ddcce60c-7d28-6fd3-2508-07df49ebf177-49ebf160, #w-node-ddcce60c-7d28-6fd3-2508-07df49ebf17e-49ebf160, #w-node-ddcce60c-7d28-6fd3-2508-07df49ebf185-49ebf160 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_83627e0a-52e0-2990-8d44-851b37eb104c-4a6ee739, #w-node-_66f4174c-071a-2241-58d5-20d2844c0ee1-4a6ee73c, #w-node-_71d3e7d6-5fd2-17fb-1098-41af6bfa4bc7-4a6ee746 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}


