.holder{
	grid-template-columns: minmax(260px, 0.15fr) 1fr minmax(245px, 0.15fr) !important;
}
.fs-labels p{
	font-size: 12px !important;
}
.projects-column{
	height: calc(100vh - 220px);
}
.settings-wrap{
	height: calc(100vh - 105px);
}
.projects-column.grid{
	height: auto;
}
.projects-wrapper.grid{
	height: calc(100vh - 200px);
}
.documentation-content{
	height: calc(100vh - 150px);
}
/*scrollbar width*/
.scroll-wrap::-webkit-scrollbar {
	width:6px;
}
/*scrollbar track*/
.scroll-wrap::-webkit-scrollbar-track {
	background:rgba(255, 0, 0, 0);
	border-width:1px;
	border-style:none;
}
/*scrollbar thumb*/
.scroll-wrap::-webkit-scrollbar-thumb {
	background:rgb(203, 202, 214);
	border-radius:10000px;
}
/*horizontal scrollbar width*/
.projects-wrapper::-webkit-scrollbar {
  width:16px;
}
/*horizontal scrollbar track*/
.projects-wrapper::-webkit-scrollbar-track {
  background:rgb(248, 248, 248);
  border-radius:1000px;
}
/*horizontal scrollbar thumb*/
.projects-wrapper::-webkit-scrollbar-thumb{
  background:rgb(203, 202, 214);
  border-width:3px;
  border-style:solid;
  border-color:rgb(248, 248, 248);
  border-radius:1000px;
}
/*Scrollbar width*/
.tabs-content::-webkit-scrollbar,
.settings-wrap::-webkit-scrollbar{
  width:6px;
}
/*Scrollbar track*/
.tabs-content::-webkit-scrollbar-track,
.settings-wrap::-webkit-scrollbar-track{
  background:rgba(255, 255, 255, 0);
}
/*Scrollbar thumb*/
.tabs-content::-webkit-scrollbar-thumb,
.settings-wrap::-webkit-scrollbar-thumb{
  background:rgb(203, 202, 214);
  border-radius:10000px;
}
/*width*/
.documentation-content::-webkit-scrollbar,
.invoices-wrapper::-webkit-scrollbar{
  width:6px;
}
/*track*/
.documentation-content::-webkit-scrollbar-track,
.invoices-wrapper::-webkit-scrollbar-track{
  background:rgba(255, 255, 255, 0);
}
/*thumb*/
.documentation-content::-webkit-scrollbar-thumb,
.invoices-wrapper::-webkit-scrollbar-thumb{
  background:rgb(203, 202, 214);
  border-radius:1000px;
}
.invoices-wrapper{
	height: calc(100vh - 107px);
}
.tab-panel{
	height: calc(100vh - 460px);
}
/*Scrollbar width*/
.tab-panel::-webkit-scrollbar{
  width:6px;
}
/*Scrollbar track*/
.tab-panel::-webkit-scrollbar-track{
  background:rgba(255, 255, 255, 0);
}
/*Scrollbar thumb*/
.tab-panel::-webkit-scrollbar-thumb{
  background:rgb(203, 202, 214);
  border-radius:10000px;
}
.ms-test-label{
	display: none;
}
/*width*/
.doc-dd__list::-webkit-scrollbar,
.members-list::-webkit-scrollbar{
  width:6px;
}
/*track*/
.doc-dd__list::-webkit-scrollbar-track,
.members-list::-webkit-scrollbar-track{
  background:rgba(255, 255, 255, 0);
}
/*thumb*/
.doc-dd__list::-webkit-scrollbar-thumb,
.members-list::-webkit-scrollbar-thumb{
  background:rgb(203, 202, 214);
  border-radius:1000px;
}
@media only screen and (min-width: 991px) {
  .menu-left{
    opacity: 1 !important;
    display: flex !important;
  }
  .menu-right{
    opacity: 1 !important;
    display: block !important;
  }
}
/*Custom responsive for Project template page*/
@media only screen and (max-width: 1680px){
  .tab-panel{
		height: calc(100vh - 550px);
	}
}
@media only screen and (max-width: 1280px) {
	.members-wrapper{
		flex-wrap: wrap;
  }
  .border-vertical{
  	margin: 0px 20px;
  }
  .dates-wrapper{
  	padding-right: 20px;
    margin-right: 20px;
  }
  .tab-panel{
		height: calc(100vh - 665px);
	}
}
@media only screen and (max-width: 991px) {
  .tab-panel{
		height: 100%;
	}
}
/*Landscape custom responsive:*/
@media only screen and (max-width: 767px) {
	.projects-column{
  	height: calc(100vh - 260px);
  }
  .projects-wrapper.grid{
  	height: calc(100vh - 290px);
  }
}